import { formatoFecha } from '@/utils/fechas'
import { tiposEstados } from '@/utils/entregaLuminarias/entregaLuminariasUtils'
import {
  estiloColumns,
  estiloRows,
  estiloSubtitulos,
  estiloTitulos,
} from '@/utils/reportes/estilosReportes/estilosReportesGenerales'

export const tituloPrincipal = {
  titulo: 'ALUMBRADO PUBLICO DE MIXCO, S.A',
  style: estiloTitulos,
}
export const tituloSecundario = {
  titulo: 'LUMINARIAS RECOLECTADAS',
  style: estiloTitulos,
}
export const subtitulo = {
  subtitulo: 'REPORTE GENERAL DE LUMINARIAS RECOLECTADAS',
  style: estiloSubtitulos,
}
export const tituloDescarga = 'REPORTE GENERAL DE LUMINARIAS RECOLECTADAS'

export function getColumns() {
  return [
    {
      label: 'Ticket No.',
      width: 10,
      style: estiloColumns,
    },
    {
      label: 'Poste No.',
      width: 20,
      style: estiloColumns,
    },
    {
      label: 'Potencia',
      width: 20,
      style: estiloColumns,
    },
    {
      label: 'Serie de Lámpara',
      width: 20,
      style: estiloColumns,
    },
    {
      label: 'Técnico Encargado',
      width: 20,
      style: estiloColumns,
    },
    {
      label: 'Estado',
      width: 20,
      style: estiloColumns,
    },
    {
      label: 'Fecha de Sustitución',
      width: 20,
      style: estiloColumns,
    },
  ]
}
export function getRows(rows) {
  const listado = []

  rows.forEach(row => {
    const fechaFormat = formatoFecha(row.fechaCreacion)
    const tiposEstado = tiposEstados[row.estado]
    const potencia = row.seguimiento[0] && row.seguimiento[0].datosPunto && row.seguimiento[0].datosPunto.potencia ? row.seguimiento[0].datosPunto.potencia : ''
    const serie = row.seguimiento[0] && row.seguimiento[0].datosPunto && row.seguimiento[0].datosPunto.serieLampara ? row.seguimiento[0].datosPunto.serieLampara : ''
    const item = {
      ticket: {
        value: row.ticket,
        type: 'number',
        style: estiloRows,
      },
      poste: {
        value: row.puntoIluminacion,
        type: 'string',
        style: estiloRows,
      },
      potencia: {
        value: potencia,
        type: 'string',
        style: estiloRows,
      },
      serie: {
        value: serie,
        type: 'string',
        style: estiloRows,
      },
      tecnicoEncargado: {
        value: row.tecnicoEncargado.nombre,
        type: 'string',
        style: estiloRows,
      },
      estado: {
        value: tiposEstado,
        type: 'string',
        style: estiloRows,
      },
      fechaCreacion: {
        value: fechaFormat,
        type: 'string',
        style: estiloRows,
      },
    }
    listado.push(item)
  })

  return listado
}
